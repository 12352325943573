import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = {
  hidden: "",
  type: "button",
  ref: "openModalNewSaleForm",
  class: "btn btn-primary btn-active-light-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#ModalNewSaleForm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!
  const _component_SaleInformation = _resolveComponent("SaleInformation")!
  const _component_NewSalesInitial = _resolveComponent("NewSalesInitial")!
  const _component_ClientForm = _resolveComponent("ClientForm")!
  const _component_Drawer = _resolveComponent("Drawer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Table, { isActionAdd: _ctx.actionAdd }, null, 8, ["isActionAdd"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SaleInformation, {
          onNewCliente: _ctx.handlerShowNewCliente,
          isActionAdd: _ctx.actionAdd
        }, null, 8, ["onNewCliente", "isActionAdd"])
      ])
    ]),
    _createVNode(_component_NewSalesInitial, {
      onOpenModal: _ctx.handlerOpenModal,
      isActionAdd: _ctx.actionAdd,
      onNewCliente: _ctx.handlerShowNewCliente
    }, null, 8, ["onOpenModal", "isActionAdd", "onNewCliente"]),
    _createVNode(_component_Drawer, {
      isOpen: _ctx.showForm,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showForm = false)),
      screenPercentage: 45
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ClientForm, { onCloseContent: _ctx.closeContent }, null, 8, ["onCloseContent"])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createElementVNode("button", _hoisted_5, null, 512)
  ]))
}