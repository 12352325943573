
import { SalesTypes } from "@/core/enums/sales-types";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { computed, defineComponent, onMounted, ref } from "vue"
import SaleInformation from "./SalesInformation.vue"
import Table from "./TableSalesInformation.vue"
import NewSalesInitial from "../modals/NewSalesInitial.vue"
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import { getModule } from "vuex-module-decorators";
import ClientForm from '../../clients/components/form/ClientForm.vue';
import Drawer from "@/components/shared/Drawer.vue"
import InfoSale from "@/models/sales/InfoSale";
import NewClientModule from "@/store/modules/client/modules/new-client";

export default defineComponent({
    props:{
        action: {
            type: String
            , required: true
        }
    },
    components:{
         SaleInformation
        ,Table
        ,NewSalesInitial
        ,ClientForm
        ,Drawer
    },
    setup(props){
        const actionAdd = ref(SalesTypes.CreateSale);
        const openModalNewSaleForm = ref(HTMLButtonElement);
        const moduleSales = getModule(NewSaleModule);
        const showForm = ref(false);
        const moduleForm = getModule(NewClientModule);

        moduleForm.getComboWayToPay();
        moduleForm.getComboUseCfdi();
        moduleForm.getComboPaymentTypes();
        moduleForm.getComboRegime();


        // setCurrentPageBreadcrumbs( translate('BREADCRUMB.NEWSALES'), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALES'), to: "sales" }]);
        
        const handlerShowNewCliente = () => {
            showForm.value = true;
        }

        const closeContent = (id: string) => {
          let actionModule: Promise<any>;

          actionModule = moduleSales.SEARCH_CLIENTS('');

          actionModule.then((isCorrect) => {
              showForm.value = false;
              moduleSales.SAVE_CLIENT_SELECTED(id);

                const saleDataInital = {
                    customerId: id,
                    observation: infoSale.value.observation,
                    receiver: infoSale.value.receiver,
                    saleTypeId: infoSale.value.saleTypeId
                } as InfoSale;

                moduleSales.SET_INITIAL_DATA_SALE(saleDataInital);
          });

        }

        const handlerOpenModal = () => {
            if(openModalNewSaleForm.value){
                (openModalNewSaleForm.value as unknown as HTMLButtonElement).click();
            }
        }

        onMounted(() => {
            let view: string | undefined = props.action == "new" ? 'Ventas'
                                                : (props.action == "edit" ? 'Ventas' 
                                                : props.action == "view" ? 'Ventas'
                                                : props.action == "newQuote" ? 'Cotizaciones'
                                                : props.action == "editQuote" ? 'Cotizaciones'
                                                : props.action == "viewQuote" ? 'Cotizaciones'  : 'Ventas');

            let viewTo: string | undefined = props.action == "new" ? 'sales'
            : (props.action == "edit" ? 'sales' 
            : props.action == "view" ? 'sales'
            : props.action == "newQuote" ? 'salesQuote'
            : props.action == "editQuote" ? 'salesQuote'
            : props.action == "viewQuote" ? 'salesQuote'  : 'sales');

            let action: SalesTypes | undefined = props.action == "new" ? SalesTypes.CreateSale
                                                : (props.action == "edit" ? SalesTypes.UpdateSale 
                                                : props.action == "view" ? SalesTypes.ReadSale
                                                : props.action == "newQuote" ? SalesTypes.CreateQuote
                                                : props.action == "editQuote" ? SalesTypes.UpdateQuote
                                                : props.action == "viewQuote" ? SalesTypes.ReadQuote  : SalesTypes.CreateSale);
            actionAdd.value = action;

             if(openModalNewSaleForm.value && action == SalesTypes.CreateSale 
               || openModalNewSaleForm.value && action == SalesTypes.CreateQuote){
                  (openModalNewSaleForm.value as unknown as HTMLButtonElement).click();
             }

            setCurrentPageBreadcrumbs( 'Detalle', [translate('BREADCRUMB.MODULES'), { name: view, to: viewTo }]);
        });

      const infoSale = computed(() => moduleSales.getValuesForm);


       return {
           actionAdd 
           , openModalNewSaleForm
           , showForm
           , infoSale

           ,closeContent
           ,handlerOpenModal
           ,handlerShowNewCliente
       }
    }
})
